@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,900;1,300;1,400;1,500;1,600;1,900&display=swap);
html, body, #root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-control {
  margin: 6px 0 20px 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .input-control {
    margin: 6px 0 10px 0;
  }
}

.flex {
  flex: 1 1
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.flex-col {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.gap-0 {
  grid-gap: 0px;
  gap: 0px;
}

.gap-10 {
  grid-gap: 10px;
  gap: 10px;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pt {
  padding-top: 15px;
}

.pb {
  padding-bottom: 15px;
}

.mt {
  margin-top: 15px;
}

.mb {
  margin-bottom: 15px;
}
.container {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.panel-left {
    visibility: hidden;
    height: 100%;
    flex: 1 1;
    background-color: #00ADEE;
    background-size: cover;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
@media all and (min-width: 768px) {
    .panel-left {
        visibility: visible;
    }
}
.panel-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 300px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    transition: all 4s ease;
    overflow-y: auto;
}
@media all and (min-width: 920px) {
    .panel-right {
        width: 50%;
        max-width: 600px;
    }
}
@media all and (min-width: 1200px) {
    .panel-right {
        width: 40%;
        max-width: 600px;
    }
}
.panel-left::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
}
